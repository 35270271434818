<template>
	<div>
		<div class="dashboard-banner">
			<div class="logo-large-container">
				<img :src="`https://wefix-files.s3.eu-west-2.amazonaws.com/images/logos/${carrierDetails.name}/${carrierDetails.logoPath}.svg`" class="logo" :alt="`${carrier}`">
			</div>
		</div>
		<div class="container">
			<div class="row">
				<div class="col-md-12 mb-2">
					<div class="card-grid grid-col-sm-2">
						<!-- Book in device -->
						<div class="card-link-container">
							<router-link :to="{ name: 'repairSearch'}" class="card card-body card-link vertical">
								<icon-device-received size="2x"></icon-device-received>
								<h5>Find a device</h5>
							</router-link>
						</div>
						<!-- Book in device -->
						<div class="card-link-container">
							<router-link :to="{ name: 'deviceSearch'}" class="card card-body card-link vertical">
								<icon-device-status size="2x"></icon-device-status>
								<h5>Update device status</h5>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex';

	export default {
		computed: {
			...mapGetters([
				'carrier',
				'carrierDetails'
			])
		}
	}
</script>